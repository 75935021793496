(function inbenta() {
  window.GlobalSite = window.GlobalSite || {};
  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite'),
    getCookieByName = GlobalSite.checkDependency('GlobalSite.getCookieByName'),
    getEnv = GlobalSite.checkDependency('GlobalSite.getEnv'),
    getClient = GlobalSite.checkDependency('GlobalSite.getClient'),
    CLIENT = getClient(),
    logFactory = GlobalSite.checkDependency('GlobalSite.logFactory'),
    log = logFactory('inbentaConnector'),
    inbentaEnv = {
      qualif: 'development',
      preprod: 'preproduction',
      prod: 'production',
    };

  let config = JSON.parse(localStorage.getItem('faqSeo') || '{}') || {};
  let inbentaSearchedTerms =
    JSON.parse(sessionStorage.getItem('inbentaSearchedTerms') || '{}') || {};
  let inbentaFederatedSearchedTerms =
    JSON.parse(sessionStorage.getItem('inbentaFederatedSearchedTerms') || '{}') || {};
  let inbentaCategoriesContents =
    JSON.parse(sessionStorage.getItem('inbentaCategoriesContents') || '{}') || {};
  let inbentaCrossSelling = JSON.parse(sessionStorage.getItem('inbentaCrossSelling') || '{}') || {};
  function isTracking() {
    const oneTrustCookie = getCookieByName('OptanonConsent');
    const decodedCookie = decodeURIComponent(
      (oneTrustCookie || []).find((entry) => entry.startsWith('C00')),
    );
    const consentForTracking =
      decodedCookie !== 'undefined' ? decodedCookie.split(',')[1].split(':')[1] : '0';
    return !!Number(consentForTracking);
  }

  function clearOutInbentaSessionStorage() {
    sessionStorage.removeItem('inbentaSearchedTerms');
    sessionStorage.removeItem('inbentaFederatedSearchedTerms');
    sessionStorage.removeItem('inbentaCategoriesContents');
    sessionStorage.removeItem('inbentaPopular');
    sessionStorage.removeItem('inbentaHowAskModal');
    sessionStorage.removeItem('inbentaUrlsProduct');
    sessionStorage.removeItem('inbentaCrossSelling');
  }

  function isExpire() {
    return !config?.session?.expiration || new Date().getTime() > config?.session?.expiration;
  }

  function setConfig(data) {
    config = { ...config, ...data, env: inbentaEnv[getEnv()] };
    localStorage.setItem('faqSeo', JSON.stringify(config));
  }

  function setInbentaSearchedTerms(data) {
    inbentaSearchedTerms = { ...inbentaSearchedTerms, ...data };
    sessionStorage.setItem('inbentaSearchedTerms', JSON.stringify(inbentaSearchedTerms));
  }

  function setInbentaFederatedSearchedTerms(data) {
    inbentaFederatedSearchedTerms = { ...inbentaFederatedSearchedTerms, ...data };
    sessionStorage.setItem(
      'inbentaFederatedSearchedTerms',
      JSON.stringify(inbentaFederatedSearchedTerms),
    );
  }
  function setInbentaCategoriesContents(data) {
    inbentaCategoriesContents = { ...inbentaCategoriesContents, ...data };
    sessionStorage.setItem('inbentaCategoriesContents', JSON.stringify(inbentaCategoriesContents));
  }

  function setInbentaCrossSelling(data) {
    inbentaCrossSelling = { ...inbentaCrossSelling, ...data };
    sessionStorage.setItem('inbentaCrossSelling', JSON.stringify(inbentaCrossSelling));
  }
  function getHeaders(additionalHeaders, api) {
    let headers = {
      'x-inbenta-key': api.key,
      'x-inbenta-env': config.env,
      'Content-Type': 'application/json',
    };

    if (api.user !== undefined) {
      headers['x-inbenta-user-type'] = api.user;
    }

    if (api.accessToken) {
      headers['Authorization'] = `Bearer ${api.accessToken}`;
    }

    if (api.session) {
      headers['x-inbenta-session'] = api.session;
    }

    if (additionalHeaders) {
      headers = { ...headers, ...additionalHeaders };
    }

    return headers;
  }
  function reconstructAuthData(data) {
    let recontructedData = {};
    if (CLIENT === 'cetelem') {
      recontructedData = {
        knowledge: {
          accessToken: data?.knowledgeApi?.accessToken,
          key: data?.knowledgeApi?.key,
          url: data?.knowledgeApi?.apis?.knowledge,
          user: 1,
        },
        search: {
          accessToken: data?.searchApi?.accessToken,
          key: data?.searchApi?.key,
          url: data?.searchApi?.apis?.search,
          user: 0,
        },
        session: {
          expiration: Number(`${data?.knowledgeApi?.expiration}000`),
        },
      };
    } else {
      recontructedData = {
        knowledge: {
          accessToken: data?.knowledgeApi?.accessToken,
          key: data?.knowledgeApi?.key,
          url: data?.knowledgeApi?.apis?.knowledge,
          user: 0,
        },
        session: {
          expiration: Number(`${data?.knowledgeApi?.expiration}000`),
        },
      };
    }
    return recontructedData;
  }
  async function request(url, data, additionalHeaders, apiName) {
    if (isExpire()) {
      if (await auth()) {
        return request(url, data, additionalHeaders, apiName);
      }
    } else {
      const api = config[apiName || 'knowledge'];
      data.headers = getHeaders(additionalHeaders, api);
      const fullUrl = `${api.url}${url}`;
      return fetch(fullUrl, data);
    }
  }
  function getSession(apiName, route) {
    return request(route, { method: 'POST' }, {}, apiName);
  }
  async function initSession() {
    try {
      const response = await getSession('knowledge', '/v1/inbtrck/session'),
        responseSearch = await getSession('search', '/v1/tracking/session');
      if (response.ok && responseSearch.ok && response.status === 200 && response.status === 200) {
        const responseJson = await response.json(),
          responseSearchJson = await responseSearch.json(),
          sessionKmToken = responseJson.sessionToken,
          sessionSearchToken = responseSearchJson.sessionToken;
        config.knowledge.session = sessionKmToken;
        config.search.session = sessionSearchToken;
        setConfig(config);
        return Promise.resolve(log.log('Session initilized...'));
      } else {
        log.error({
          message: 'problem intializing the session token',
          error: await response.json(),
        });
        Promise.resolve({
          message: 'problem intializing the session token',
          error: await response.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem intializing the session token', error: e });
      return Promise.resolve({ message: 'problem intializing the session token', error: e });
    }
  }
  let index = 0;
  async function auth() {
    if (config.session && !isExpire()) {
      return Promise.resolve(true);
    } else {
      try {
        const getTokenResponse = await fetch(`/fr/faq.auth.${CLIENT}.json`, { cache: 'no-store' });
        index = index + 1;
        if (getTokenResponse.status === 200 && getTokenResponse.ok && index < 3) {
          const jsonResponse = await getTokenResponse.json();
          setConfig(reconstructAuthData(jsonResponse));
          await initSession();
          clearOutInbentaSessionStorage();
          return Promise.resolve(true);
        } else {
          log.error({ 'Get Token API ERROR: ': getTokenResponse });
          return Promise.resolve(false);
        }
      } catch (e) {
        log.error({ 'Get Token API ERROR: ': e });
        return Promise.resolve(false);
      }
    }
  }
  async function search(payload, source = 'faq') {
    const term = payload.query.trim();
    if (inbentaSearchedTerms[term]) {
      return inbentaSearchedTerms[term];
    }
    try {
      const searchResponse = await request(
        '/v1/search',
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        { 'x-inbenta-source': source },
      );
      if (searchResponse.ok && searchResponse.status === 200) {
        const searchResponseJson = await searchResponse.json();
        const obj = {};
        obj[term] = searchResponseJson;
        setInbentaSearchedTerms(obj);
        return searchResponseJson;
      } else {
        log.error({
          message: 'problem when calling search api',
          error: await searchResponse.json(),
        });
        return Promise.resolve({
          message: 'problem when calling search api',
          error: await searchResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling search api', error: e });
      return Promise.resolve({ message: 'problem when calling search api', error: e });
    }
  }
  async function getPopular(source = 'faq') {
    const inbentaPopular = JSON.parse(sessionStorage.getItem('inbentaPopular'));
    if (inbentaPopular) {
      return inbentaPopular;
    }
    try {
      const popularResponse = await request(
        '/v1/contents/popular',
        {
          method: 'GET',
        },
        { 'x-inbenta-source': source },
      );
      if (popularResponse.ok && popularResponse.status === 200) {
        const popularResponseJson = await popularResponse.json();
        sessionStorage.setItem('inbentaPopular', JSON.stringify(popularResponseJson));
        return popularResponseJson;
      } else {
        log.error({
          message: 'problem when calling popular api',
          error: await popularResponse.json(),
        });
        return Promise.resolve({
          message: 'problem when calling popular api',
          error: await popularResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling popular api', error: e });
      return Promise.resolve({ message: 'problem when calling popular api', error: e });
    }
  }
  function groubByParent(categories, key = 'parent') {
    return categories.reduce((acc, next) => {
      if (acc[next[key]]) {
        acc[next[key]].push(next);
      } else {
        acc[next[key]] = [next];
      }
      return acc;
    }, {});
  }

  async function getCategories(depth = 1, source = 'faq') {
    const inbentaCategories = JSON.parse(sessionStorage.getItem('inbentaCategories'));
    if (inbentaCategories) {
      return inbentaCategories;
    }
    try {
      const categoriesResponse = await request(
        `/v1/categories?maxDepth=${depth}`,
        {
          method: 'GET',
        },
        { 'x-inbenta-source': source },
      );
      if (categoriesResponse.ok && categoriesResponse.status === 200) {
        const categoriesResponseJson = await categoriesResponse.json();
        const groubedCategories = groubByParent(categoriesResponseJson?.results);
        sessionStorage.setItem('inbentaCategories', JSON.stringify(groubedCategories));
        return groubedCategories;
      } else {
        log.error({
          message: 'problem when calling categories api',
          error: await categoriesResponse.json(),
        });
        return Promise.resolve({
          message: 'problem when calling categories api',
          error: await categoriesResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling categories api', error: e });
      return Promise.resolve({ message: 'problem when calling categories api', error: e });
    }
  }

  async function getCategoryContents(category, source = 'faq') {
    if (!category) {
      log.error({
        message: 'problem when calling contents api',
        error: 'No category was specified',
      });
      return Promise.resolve({
        message: 'problem when calling contents api',
        error: 'No category was specified',
      });
    }
    if (inbentaCategoriesContents[category]) {
      return inbentaCategoriesContents[category];
    }
    try {
      const contentsResponse = await request(
        `/v1/categories/${category}/contents?length=100`,
        {
          method: 'GET',
        },
        { 'x-inbenta-source': source },
      );
      if (contentsResponse.ok && contentsResponse.status === 200) {
        const contentsResponseJson = await contentsResponse.json();
        const obj = {};
        obj[category] = contentsResponseJson;
        setInbentaCategoriesContents(obj);
        return contentsResponseJson;
      } else {
        log.error({
          message: 'problem when calling contents api',
          error: await contentsResponse.json(),
        });
        Promise.resolve({
          message: 'problem when calling contents api',
          error: await contentsResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling contents api', error: e });
      return Promise.resolve({ message: 'problem when calling related api', error: e });
    }
  }
  async function getQuestionRelated(question, source = 'faq') {
    if (!question) {
      log.error({
        message: 'problem when calling related api',
        error: 'No question was specified',
      });
      return Promise.resolve({
        message: 'problem when calling related api',
        error: 'No question was specified',
      });
    }

    try {
      const relatedResponse = await request(
        `/v1/contents/${question}/related`,
        {
          method: 'GET',
        },
        { 'x-inbenta-source': source },
      );
      if (relatedResponse.ok && relatedResponse.status === 200) {
        return relatedResponse.json();
      } else {
        log.error({
          message: 'problem when calling related api',
          error: await relatedResponse.json(),
        });
        Promise.resolve({
          message: 'problem when calling related api',
          error: await relatedResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling related api', error: e });
      return Promise.resolve({ message: 'problem when calling related api', error: e });
    }
  }

  async function getModalHtml(source = 'faq') {
    const inbentaHowAskModal = JSON.parse(sessionStorage.getItem('inbentaHowAskModal'));
    if (inbentaHowAskModal) {
      return inbentaHowAskModal;
    }
    try {
      const appDataResponse = await request(
        `/v1/app/data/appdata`,
        {
          method: 'GET',
        },
        { 'x-inbenta-source': source },
      );
      if (appDataResponse.ok && appDataResponse.status === 200) {
        const appDataResponseJson = await appDataResponse.json();
        sessionStorage.setItem('inbentaHowAskModal', JSON.stringify(appDataResponseJson));
        return appDataResponseJson;
      } else {
        log.error({
          message: 'problem when calling appData api',
          error: await relatedResponse.json(),
        });
        Promise.resolve({
          message: 'problem when calling appData api',
          error: await relatedResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling appData api', error: e });
      return Promise.resolve({ message: 'problem when calling appData api', error: e });
    }
  }

  async function federatedSearch(payload, source = 'faq') {
    const term = payload.requests[0].query.trim();
    if (inbentaFederatedSearchedTerms[term]) {
      return inbentaFederatedSearchedTerms[term];
    }
    try {
      const searchResponse = await request(
        '/v1/federated-searches',
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        { 'x-inbenta-source': source },
        'search',
      );
      if (searchResponse.ok && searchResponse.status === 200) {
        const searchResponseJson = await searchResponse.json();
        const obj = {};
        obj[term] = {
          results: searchResponseJson?.results[0]?.results,
          tracking: searchResponseJson?.results[0]?.tracking,
        };
        setInbentaFederatedSearchedTerms(obj);
        return searchResponseJson?.results[0];
      } else {
        log.error({
          message: 'problem when calling federated search api',
          error: await searchResponse.json(),
        });
        return Promise.resolve({
          message: 'problem when calling federated search api',
          error: await searchResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling federated search api', error: e });
      return Promise.resolve({ message: 'problem when calling federated search api', error: e });
    }
  }

  async function sendTrackingEvents(payload, source = 'faq', apiName) {
    if (!payload) {
      log.error({ message: 'problem when calling tracking api', error: 'payload not set' });
      return Promise.resolve({
        message: 'problem when calling tracking api',
        error: 'payload not set',
      });
    }

    try {
      const eventResponse = await request(
        '/v1/inbtrck/events',
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        { 'x-inbenta-source': source },
        apiName,
      );
      if (eventResponse.ok && eventResponse.status === 200) {
        return Promise.resolve({
          message: 'success',
          error: false,
        })
      } else {
        return Promise.resolve({
          message: 'problem when calling tracking api',
          error: await eventResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling tracking api', error: e });
      return Promise.resolve({ message: 'problem when calling tracking api', error: e });
    }
  }

  async function getUrlsProduct(source = 'faq') {
    const inbentaUrlsProduct = JSON.parse(sessionStorage.getItem('inbentaUrlsProduct'));
    if (inbentaUrlsProduct) {
      return inbentaUrlsProduct;
    }
    try {
      const urlsProductResponse = await request(
        '/v1/app/data/URLs_produit',
        {
          method: 'GET',
        },
        { 'x-inbenta-source': source },
      );
      if (urlsProductResponse.ok && urlsProductResponse.status === 200) {
        const urlsProductResponseJson = await urlsProductResponse.json();
        sessionStorage.setItem('inbentaUrlsProduct', JSON.stringify(urlsProductResponseJson));
        return urlsProductResponseJson;
      } else {
        log.error({
          message: 'problem when calling URLs_produit api',
          error: await urlsProductResponse.json(),
        });
        return Promise.resolve({
          message: 'problem when calling URLs_produit api',
          error: await urlsProductResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling URLs_produit api', error: e });
      return Promise.resolve({ message: 'problem when calling URLs_produit api', error: e });
    }
  }

  async function getUrlsProductContents(contentsIds, source = 'faq') {
    if (!contentsIds) {
      log.error({
        message: 'problem when calling urls product contents api',
        error: 'contentsIds missing',
      });
      return Promise.resolve({
        message: 'problem when calling urls product contents api',
        error: 'contentsIds missing',
      });
    }
    try {
      const urlsProductContentsResponse = await request(
        `/v1/contents/ids?ids=${contentsIds}`,
        {
          method: 'GET',
        },
        { 'x-inbenta-source': source },
      );
      if (urlsProductContentsResponse.ok && urlsProductContentsResponse.status === 200) {
        const urlsProductContentsResponseJson = await urlsProductContentsResponse.json();
        return urlsProductContentsResponseJson;
      } else {
        log.error({
          message: 'problem when calling urls product contents api',
          error: await urlsProductContentsResponse.json(),
        });
        return Promise.resolve({
          message: 'problem when calling urls product contents api',
          error: await urlsProductContentsResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling urls product contents api', error: e });
      return Promise.resolve({
        message: 'problem when calling urls product contents api',
        error: e,
      });
    }
  }
  async function getCrossSelling(id = 2, source = 'faq') {
    if (inbentaCrossSelling[id]) {
      return inbentaCrossSelling[id];
    }
    try {
      const crossSellingResponse = await request(
        `/v1/cross-selling/${id}`,
        {
          method: 'GET',
        },
        { 'x-inbenta-source': source },
      );
      if (crossSellingResponse.ok && crossSellingResponse.status === 200) {
        const crossSellingResponseJson = await crossSellingResponse.json();
        const obj = {};
        obj[id] = crossSellingResponseJson;
        setInbentaCrossSelling(obj);
        return crossSellingResponseJson;
      } else {
        log.error({
          message: 'problem when calling crossselling api',
          error: await urlsProductContentsResponse.json(),
        });
        return Promise.resolve({
          message: 'problem when calling crosssellings api',
          error: await urlsProductContentsResponse.json(),
        });
      }
    } catch (e) {
      log.error({ message: 'problem when calling crossselling api', error: e });
      return Promise.resolve({ message: 'problem when calling crossselling api', error: e });
    }
  }

  const inbentaApi = {
    auth,
    search,
    getPopular,
    getCategories,
    groubByParent,
    getCategoryContents,
    getQuestionRelated,
    getModalHtml,
    isTracking,
    federatedSearch,
    getUrlsProduct,
    getUrlsProductContents,
    getCrossSelling,
    sendTrackingEvents,
  };

  exportToGlobalSite({
    inbentaApi,
  });
})();
